import React, { forwardRef, useState } from "react";
import { Card, CardMedia, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../../app/helpers";
import { RootState } from "../../../app/rootReducer";
import { setFloors, setInputs } from "../../../features/Booking-Form/slices/booking.slice";
import { LocationAvailability } from "../../../features/Booking-Form/typings/booking-location";
import { LocationInputs } from "../../../features/Booking-Form/typings/inputs-location";
import { ReactComponent as StopIcon } from "../../../features/FloorManager/assets/no-stopping.svg";
import { InfoIconWithTooltip } from "../../Title/InfoIcon";
import MWImage from "../../../features/Booking-Form/assets/mobile-working.jpeg";
import { SlideoutPanelDialog } from "../../SlideoutPanel/SlideoutPanelDialog.component";
import { ColleagueBookingDataContents } from "../LocationColleagueDialog/ColleagueBookingDataContents.component";
import { genConnectedBookingData } from "../../BookingForm/BookingFilter/functions/BookingFilterButtonGroup.functions";
import { BookingType } from "../../../features/Booking-Form/typings/booking-inputs";
import { LocationCardContent } from "./LocationCardContent";

type P = {
  location: LocationAvailability;
  locationToState({
    selectedLocation,
    locationName,
    timezone,
    isMobileWorking,
    automatedSeating
  }: LocationInputs): void;
};

export const LocationCardMT: React.FC<P> = forwardRef<HTMLDivElement, P>(
  ({ location, locationToState }, ref) => {
    const usersBookedFor = useSelector((state: RootState) => state.booking.inputs.usersBookedFor);
    const inputs = useSelector((state: RootState) => state.booking.inputs);

    const {
      numberOfBookableObjects,
      name,
      picturePath,
      isMobileWorking,
      id,
      allowForBooking,
      timezone,
      automatedSeatingObjects,
      connectedBookingData
    } = location;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isColleagueDataOpen, setIsColleagueDataOpen] = useState(false);

    const spotsAvailable =
      numberOfBookableObjects > 0 &&
      usersBookedFor &&
      usersBookedFor.length <= numberOfBookableObjects &&
      !isMobileWorking;

    const isMobileWorkingReady = isMobileWorking && allowForBooking && numberOfBookableObjects > 0;

    const isDisabled =
      !allowForBooking || isMobileWorking ? t("_locationDisabled") : t("_spacesOccupied");

    const isAutomatedSeatingAvailable =
      name !== "Mobile Working" && inputs.mode === BookingType.WORKPLACE && spotsAvailable;

    const uniqConnectedBookingData = genConnectedBookingData(connectedBookingData);

    const isLocationSelectable =
      (numberOfBookableObjects > 0 &&
        allowForBooking &&
        usersBookedFor &&
        usersBookedFor?.length <= numberOfBookableObjects) ||
      (isMobileWorking && allowForBooking && numberOfBookableObjects > 0);

    const locationIcons = isMobileWorkingReady ? (
      <></>
    ) : (
      <>
        <StopIcon style={{ marginRight: "8px" }} />
        <InfoIconWithTooltip tooltipText={isDisabled} />
      </>
    );

    const selectLocation = ({ automatedSeating }: { automatedSeating: boolean }) => {
      dispatch(
        setInputs({
          selectedLocation: id,
          locationName: name,
          isMobileWorking: isMobileWorking,
          timezone: timezone,
          automatedSeating: automatedSeating,
          automatedSeatingObjects: automatedSeatingObjects
        })
      );
      dispatch(setFloors({ isLoading: true, currentFloorIndex: undefined }));
      locationToState({
        selectedLocation: id,
        locationName: name,
        isMobileWorking: isMobileWorking,
        timezone: timezone,
        automatedSeating: automatedSeating,
        automatedSeatingObjects: automatedSeatingObjects
      });
    };

    function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      event.preventDefault();
      if (isLocationSelectable) selectLocation({ automatedSeating: false });
    }

    function handleAutomatedSeatingClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      event.stopPropagation();
      if (isLocationSelectable) selectLocation({ automatedSeating: true });
    }

    function handleGoogleLocationClick(address: string) {
      /**
       * @description google map link, path finding: current to location
       * use if normal finding: `https://www.google.com/maps?q=${encodeURIComponent(address)}`
       */
      const url = `https://www.google.com/maps/dir/current+location/${encodeURIComponent(address)}`;
      window.open(url, "_blank");
    }

    return (
      <>
        <Fade in={!!name} ref={ref}>
          <Card
            data-testid="location-card"
            sx={{
              display: "flex",
              width: "95vw",
              minHeight: 193,
              maxWidth: 400,
              cursor: "pointer",
              margin: "10px 20px 20px 0"
            }}
            onClick={handleClick}
          >
            <CardMedia
              sx={{ minWidth: 160, "@media (max-device-width: 400px)": { minWidth: 130 } }}
              image={picturePath ?? MWImage}
              title={name}
            />

            <LocationCardContent
              location={location}
              spotsAvailable={spotsAvailable}
              isAutomatedSeatingAvailable={isAutomatedSeatingAvailable}
              uniqConnectedBookingData={uniqConnectedBookingData}
              locationIcons={locationIcons}
              handleAutomatedSeatingClick={handleAutomatedSeatingClick}
              setIsColleagueDataOpen={setIsColleagueDataOpen}
              handleGoogleLocationClick={handleGoogleLocationClick}
            />
          </Card>
        </Fade>

        {/* slideout panel for listing connected colleagues booking data */}
        <SlideoutPanelDialog
          isOpen={isColleagueDataOpen}
          dialogTitle={t("_colleagueBookingDataDialogTitle")}
          showCloseButton={true}
          onClose={() => setIsColleagueDataOpen(false)}
          width="25vw"
          children={
            <ColleagueBookingDataContents connectedBookingData={uniqConnectedBookingData} />
          }
        />
      </>
    );
  }
);
