import { MRT_Row, MRT_TableInstance } from "material-react-table";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { EquipmentRow } from "./typings/equipment-row.types";

type Props = {
  table: MRT_TableInstance<EquipmentRow>;
  row: MRT_Row<EquipmentRow>;
  setIsDeleteModalOpen: (o: boolean) => void;
  setDeletionSelected: (s: number[]) => void;
};
export const FilterableEquipmentListRowActions = ({
  table,
  row,
  setIsDeleteModalOpen,
  setDeletionSelected
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box data-testid="filterable-equipment-list-row-box" sx={{ display: "flex", gap: "1rem" }}>
      <Tooltip
        data-testid="filterable-equipment-list-row-edit"
        title={<Typography>{t("Edit")}</Typography>}
        aria-label={"Edit"}
      >
        <IconButton
          data-testid="filterable-equipment-list-row-edit-btn"
          onClick={() => table.setEditingRow(row)}
          size="large"
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip
        data-testid="filterable-equipment-list-row-delete"
        title={<Typography>{t("Delete")}</Typography>}
        aria-label={"Delete"}
      >
        <IconButton
          data-testid="filterable-equipment-list-row-delete-btn"
          onClick={() => {
            setIsDeleteModalOpen(true);
            setDeletionSelected([row.original.id]);
          }}
          size="large"
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
