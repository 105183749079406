import { Chip, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Person } from "@mui/icons-material";
import { RemoteUserReportEntry } from "../../../../../hooks/Remote/useRemoteFetchEmployeeReport";

interface Props {
  employees?: RemoteUserReportEntry[];
}

export function NumberOfUsers({ employees }: Props) {
  const { t } = useTranslation();
  const totalCount = employees?.length || 0;

  return (
    <Tooltip
      title={<Typography>{t("NumberOfUsersLabel")}</Typography>}
      aria-label={"NumberOfUsersLabel"}
    >
      <Chip
        data-testid={"num-of-users-label"}
        sx={{ mr: 1, mb: 1 }}
        color={"primary"}
        icon={<Person />}
        label={
          totalCount > 1
            ? `${totalCount} ${t("numberOfUsersPlural")}`
            : `${totalCount} ${t("numberOfUsersSingle")}`
        }
      />
    </Tooltip>
  );
}
