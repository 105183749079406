/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Popover,
  Typography
} from "@mui/material";
import { Form, Formik, FormikErrors, FormikHelpers } from "formik";
import { ObjectType } from "myzod/libs/types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateWithSchema } from "./form.utils";
import { TFunction } from "i18next";
import { v4 as uuidv4 } from "uuid";

const generateNumberList = (number: number): number[] => {
  return Array.from({ length: number }, (v, k) => k + 1);
};

/** Wraps children in tooltip if tooltip is provided, otherwise returns children. */
export const OptionalTooltip: React.FC<{
  title: React.ReactNode;
  children: React.ReactElement<any, any>;
}> = ({ children, title }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return title ? (
    <>
      <Popover
        id="optional-tooltip"
        data-testid="optional-tooltip"
        sx={{ padding: 5, marginLeft: 2, marginRight: 2, pointerEvents: "none" }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
      >
        <Typography>{title}</Typography>
      </Popover>
      <Box
        style={{ display: "flex", alignItems: "center" }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Box>
    </>
  ) : (
    children
  );
};

function a11yProps(index: number): { id: string; "aria-controls": string } {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

interface FormikProps<T extends Record<string, any>> {
  /** Initial values of the form. */
  initialValues: T;
  /** Form submit handler. */
  handleOk: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
}

interface FormikMyzodValidation<T extends Record<string, any>> extends FormikProps<T> {
  schema?: ObjectType<any>; // could type this

  /** Function that runs when validating form. Use to provide additional validation logic. Must return formik error partial which will be spread into validation result. */
  onValidate?: (values: T) => Partial<FormikErrors<T>> | undefined;
}

// interface FormikCustomValidation<T extends Record<string, any>> extends FormikProps<T> {
//   validate: (values: T) => void | object | Promise<FormikErrors<T>>;
// }

// type FormikValidationProps<T extends Record<string, any>> =
//   | FormikMyzodValidation<T>
//   | FormikCustomValidation<T>;

type P<T extends Record<string, unknown>> = FormikMyzodValidation<T> & {
  /** Determines whether Dialog is open. */
  isOpen: boolean;
  /** @deprecated Class name for dialog wrapper. */
  className?: string;

  /** Title of Dialog */
  title?: string;
  /** Optional description, shown below title. */
  description?: React.ReactNode;

  /** Children of Form Dialog should be form inputs. If there are multiple react children, they will be shown in tabs. */
  children?: React.ReactElement<any> | (React.ReactElement<any> | null)[] | null;
  /**
   * Labels for tabs. Should be in the same order as children.
   * If there are more tabs than children, tabs which don't have a child will not be shown.
   * If there are more children than tabs, labels will default to "more"
   */
  tabLabels?: string[];

  /**
   * Disables ok button.
   * Don't use this, use form validation.
   */
  disableOK?: boolean;
  /** Label of primary button, defaults to "Confirm". */
  primaryButtonLabel?: string;
  /** Tooltip of primary button. */
  primaryButtonTooltip?: string;

  /** Form cancel handler. */
  handleCancel?(): void;
  /** Label of secondary button, defaults to "Cancel". */
  secondaryButtonLabel?: string;
  /** Hide secondary */
  hideSecondaryButton?: boolean;

  /** Modal will be shown in fullscreen if true. */
  fullScreen?: boolean;
  /** Maximum modal width */
  maxWidth?: DialogProps["maxWidth"];

  /** Modal shows loading state */
  isLoading?: boolean;
};

/**
 * Wrapper for a Popup/Modal/Dialog Box that opens/closes depending on isOpen.
 */
export const FormDialog2 = <T extends Record<any, any>>({
  title = "Dialog",
  description,
  secondaryButtonLabel = "Cancel",
  primaryButtonLabel = "Confirm",
  primaryButtonTooltip,
  isOpen,
  initialValues,
  schema,
  onValidate,
  handleCancel = undefined,
  handleOk,
  children,
  tabLabels,
  hideSecondaryButton = false,
  fullScreen,
  disableOK,
  maxWidth,
  isLoading
}: P<T>): JSX.Element => {
  const { t } = useTranslation();
  tabLabels = transformTabLabels(tabLabels, children, t);

  const [tabIndex, setTabIndex] = useState<number>(0);
  useEffect(() => {
    if (!isOpen) setTabIndex(0);
  }, [isOpen]);

  const handleTabChange = (_event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      fullScreen={fullScreen}
      disableEscapeKeyDown
      maxWidth={maxWidth}
      data-testid={`form-dialog-${title}`}
      PaperProps={{ sx: { backgroundImage: "unset", maxHeight: "80vh" } }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleOk}
        validate={values => {
          let errors: FormikErrors<T> = {};

          if (schema) errors = validateWithSchema(values, schema);
          if (onValidate)
            errors = {
              ...errors,
              ...onValidate(values)
            };

          return errors;
        }}
      >
        <Form>
          <DialogTitle style={{ overflowWrap: "break-word" }} id="form-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent id="form-dialog-content" style={{ overflow: "hidden" }}>
            {description && <DialogContentText>{description}</DialogContentText>}
            <Grid>
              {Array.isArray(children) && tabLabels ? (
                <>
                  <Tabs
                    value={tabIndex}
                    onChange={handleTabChange as any}
                    indicatorColor="primary"
                    centered
                  >
                    {getTabs(children as React.ReactElement[], tabLabels)}
                  </Tabs>
                  {getTabContent(children as React.ReactElement[], tabIndex)}
                </>
              ) : (
                children
              )}
            </Grid>
          </DialogContent>
          <DialogActions sx={{ marginTop: 2 }}>
            {isLoading && <CircularProgress sx={{ padding: "0px 20px" }} />}
            {!hideSecondaryButton && (
              <Button onClick={handleCancel} color="secondary" data-testid="form-modal-cancel">
                {t(secondaryButtonLabel)}
              </Button>
            )}
            <OptionalTooltip title={primaryButtonTooltip}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={disableOK || isLoading}
                data-testid="form-modal-submit"
              >
                {t(primaryButtonLabel)}
              </Button>
            </OptionalTooltip>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default FormDialog2;

function transformTabLabels(
  tabLabels: string[] | undefined,
  children: React.ReactElement | (React.ReactElement | null)[] | null | undefined,
  t: TFunction
) {
  if (Array.isArray(children) && children.length !== tabLabels?.length) {
    if (!tabLabels) tabLabels = generateNumberList(children.length).map(String);
    else if (tabLabels.length > children.length) tabLabels = tabLabels.slice(0, children.length);
    else if (children.length > tabLabels.length) {
      while (children.length > tabLabels.length) {
        tabLabels.push(t("More"));
      }
    }
  }

  return tabLabels;
}

const getTabContent = (tabsContent: (React.ReactElement<any> | null)[], tabIndex: number) =>
  tabsContent.map(
    (element, i) =>
      element && (
        <Box
          key={uuidv4()}
          role="tabpanel"
          hidden={tabIndex !== i}
          id={`wrapped-tabpanel-${i}`}
          aria-labelledby={`wrapped-tab-${i}`}
        >
          {element}
        </Box>
      )
  );

const getTabs = (tabsContent: (React.ReactElement<any> | null)[], labels: string[]) =>
  tabsContent.map(
    (element, index) =>
      element && <Tab label={labels[index]} key={labels[index]} {...a11yProps(index)} />
  );
