import { ElementType } from "react";
import { Chip, Tooltip, Typography } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

type Props<T> = {
  list?: T[];
  tooltipValue: string;
  Icon: ElementType<SvgIconProps>;
  singularLabel: string;
  pluralLabel: string;
};

export function CountChip({
  list,
  tooltipValue,
  Icon: Icon,
  pluralLabel,
  singularLabel
}: Props<any>) {
  const totalCount = list?.length || 0;

  return (
    <Tooltip title={<Typography>{tooltipValue}</Typography>} aria-label={tooltipValue}>
      <Chip
        sx={{ mr: 1, mb: 1 }}
        data-testid={"num-of-label"}
        color={"primary"}
        icon={<Icon />}
        label={totalCount > 1 ? `${totalCount} ${pluralLabel}` : `${totalCount} ${singularLabel}`}
      />
    </Tooltip>
  );
}
