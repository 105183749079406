import { Delete } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function BookingApprovalTopToolbar({
  disabled,
  onClick
}: {
  disabled: boolean;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", gap: "0.5rem" }}>
      <Tooltip
        title={<Typography>{t("Delete selection")}</Typography>}
        aria-label={"Delete selection"}
      >
        <span>
          <Button
            color="error"
            data-testid="handle-inactivate-booking-btn"
            disabled={disabled}
            onClick={onClick}
            variant="contained"
          >
            <Delete />
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
}
