import { FunctionComponent, useState } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  useTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { DropzoneAreaBase, FileObject } from "react-mui-dropzone";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { updateNoPlaceZoneBookingAttachments } from "../../utils/axios-requests";
import {
  Attachments,
  BookingScheduleInterface
} from "../../features/Booking-Form/typings/booking.types";
import DefaultSpinner from "../LoadingSpinner/default-spinner.component";
import { acceptableFiles } from "./constants/acceptableFileList";

type Props = {
  initialFiles?: FileObject[];
  triggerCall?: () => void;
  onFilesUploaded: (files: FileObject[]) => void;
  onFileRemoved: (file: FileObject) => void;
  appointmentDataId: { id: number };
  scheduleData: BookingScheduleInterface;
};

const FileUploadModal: FunctionComponent<Props> = ({
  initialFiles,
  triggerCall,
  onFilesUploaded,
  onFileRemoved,
  appointmentDataId,
  scheduleData
}) => {
  const [files, setFiles] = useState<FileObject[]>(initialFiles || []);
  const [modalOpen, setModalOpen] = useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function handleClickUpload() {
    if (!files || !files.length || files[0] === undefined || loading) return;

    setLoading(true);

    if (triggerCall) triggerCall();
    let successfulUploads = 0; // counter for successful uploads

    for (const fileObject of files) {
      const formData = new FormData();
      formData.append("file", fileObject.file);

      try {
        const response = await updateNoPlaceZoneBookingAttachments(appointmentDataId.id, formData);
        const uploadedAttachment: Attachments = response.data;

        scheduleData.attachments = [...scheduleData.attachments, uploadedAttachment];
        setFiles([]);
        successfulUploads++;
      } catch (error: any) {
        handleFileRemoved(fileObject);
        if (error.response.status == 413) {
          enqueueSnackbar(t("Files exceed size limit. Maximum upload size is 20MB."), {
            variant: "error"
          });
        } else {
          enqueueSnackbar(t("Uploading files failed. Try another file."), { variant: "error" });
        }
      }
    }
    if (successfulUploads === files.length) {
      // All files were successfully uploaded
      enqueueSnackbar(t("All attachments uploaded successfully"), { variant: "success" });
      setModalOpen(false);
      onFilesUploaded(files);
    }
    setLoading(false);
  }

  function handleFileRemoved(removedFile: FileObject) {
    const newFiles = files.filter(file => file !== removedFile);
    setFiles(newFiles);
    onFileRemoved(removedFile);
  }

  return (
    <>
      <Tooltip
        title={<Typography>{t("Upload files") as string}</Typography>}
        aria-label={"Upload files"}
      >
        <IconButton
          onClick={() => setModalOpen(true)}
          aria-label="delete"
          color="primary"
          size="large"
        >
          <AttachFileIcon style={{ fill: theme.palette.text.primary }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={modalOpen}
        keepMounted
        onClose={() => setModalOpen(false)}
        aria-labelledby="file-upload-dialog-title"
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { backgroundImage: "none" } }}
      >
        <DialogTitle id="file-upload-dialog-title">{t("Upload files")}</DialogTitle>
        <DialogContent>
          {loading && <DefaultSpinner />}
          {!loading && (
            <DropzoneAreaBase
              showPreviewsInDropzone
              previewGridProps={{ container: { spacing: 1, direction: "row" } }}
              dropzoneText={t("Drop a file or click to upload")}
              maxFileSize={20000000}
              filesLimit={10}
              fileObjects={files}
              showAlerts={false}
              onAdd={(filesToAdd: FileObject[]) => {
                const totalSizeOfAlreadyAddedFiles = files.reduce(
                  (totalSize, file) => totalSize + file.file.size,
                  0
                );
                const totalSizeOfNewlyAddedFiles = filesToAdd.reduce(
                  (totalSize, file) => totalSize + file.file.size,
                  0
                );
                if (totalSizeOfAlreadyAddedFiles + totalSizeOfNewlyAddedFiles > 20000000) {
                  enqueueSnackbar(t("Total file size cannot exceed 20MB"), { variant: "error" });
                } else {
                  setFiles([...files, ...filesToAdd]);
                }
              }}
              onDelete={(file: FileObject) => {
                handleFileRemoved(file);
              }}
              acceptedFiles={acceptableFiles}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} variant="outlined">
            {t("Close")}
          </Button>
          {files && files.length > 0 && (
            <Button
              data-testid="upload-btn"
              onClick={handleClickUpload}
              color="primary"
              variant="contained"
            >
              {t("Upload")}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploadModal;
