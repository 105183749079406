import { Dispatch, SetStateAction, useState } from "react";
import { Collapse, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import LabeledSlider from "../../components/Common/LabeledSlider/LabeledSlider";
import { CheckinAndMaxAdvancedBooking, MetaData } from "../Login/typings/login.types";
import { updateCheckInSettings } from "./functions/company-card.component.functions";
import { BookingType } from "../Booking-Form/typings/booking-inputs";

type P = {
  wpSetting: CheckinAndMaxAdvancedBooking;
  setWpSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  parkPlSetting: CheckinAndMaxAdvancedBooking;
  setParkPlSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  eChargePlSetting: CheckinAndMaxAdvancedBooking;
  setEChargePlSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  confZnSetting: CheckinAndMaxAdvancedBooking;
  setConfZnSetting: Dispatch<SetStateAction<CheckinAndMaxAdvancedBooking>>;
  setNewValues?: Dispatch<SetStateAction<MetaData>>;
};

export function CompanyMaximumAdvancedBookingSettingPerType({
  wpSetting,
  setWpSetting,
  parkPlSetting,
  setParkPlSetting,
  eChargePlSetting,
  setEChargePlSetting,
  confZnSetting,
  setConfZnSetting,
  setNewValues
}: P) {
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    userInformation: { placeTypes, zoneTypes }
  } = useSelector((state: RootState) => state.login);

  const [maximumAdvancedBookingExpanded, setMaximumAdvancedBookingExpanded] = useState(false);

  return (
    <>
      {/* maximum advanced booking per booking type top-drawer button */}
      <Grid
        sx={{ display: "flex", alignItems: "center", mb: "5px" }}
        data-testid="company-card-maximum-advanced-booking-expand"
      >
        <IconButton
          data-testid={"expandButton-maximum-advanced-booking"}
          sx={{
            transform: maximumAdvancedBookingExpanded ? "rotate(0deg)" : "rotate(180deg)",
            transition: theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest
            })
          }}
          onClick={() => setMaximumAdvancedBookingExpanded(!maximumAdvancedBookingExpanded)}
          aria-expanded={maximumAdvancedBookingExpanded}
          aria-label="show more"
          size="large"
        >
          <ExpandMore />
        </IconButton>
        <Typography sx={{ marginLeft: "4px" }}>{t("Maximum advanced booking")}</Typography>
      </Grid>

      {/* maximum advanced booking time slider per booking type */}
      <Collapse
        in={maximumAdvancedBookingExpanded}
        timeout="auto"
        unmountOnExit
        data-testid="company-card-maximum-advanced-booking-collapse"
      >
        {/* maxAdvanced booking time for WORKPLACE */}
        {placeTypes.filter(t => t.id === 1) && (
          <LabeledSlider
            marginRight="5px"
            value={wpSetting.maxWeeksInAdvance || 6}
            label={t("_companyMaximumAdvancedBookingLabel", {
              type: t("Workplace")
            })}
            onChange={(e, v) => {
              /* istanbul ignore next */
              setWpSetting(prev => ({ ...prev, maxWeeksInAdvance: v as number }));
              /* istanbul ignore next */
              setNewValues?.(prevState =>
                updateCheckInSettings(prevState, BookingType.WORKPLACE, {
                  ...wpSetting,
                  maxWeeksInAdvance: v as number
                })
              );
            }}
            min={1}
            max={15}
            step={1}
            data-testid={"maximumAdvancedWorkplaceBooking"}
          />
        )}

        {/* maxAdvanced booking time for PARKINGPLACE */}
        {placeTypes.filter(t => t.id === 2) && (
          <LabeledSlider
            marginRight="5px"
            value={parkPlSetting.maxWeeksInAdvance || 6}
            label={t("_companyMaximumAdvancedBookingLabel", {
              type: t("Parking")
            })}
            onChange={(e, v) => {
              /* istanbul ignore next */
              setParkPlSetting(prev => ({ ...prev, maxWeeksInAdvance: v as number }));
              /* istanbul ignore next */
              setNewValues?.(prevState =>
                updateCheckInSettings(prevState, BookingType.PARKINGPLACE, {
                  ...parkPlSetting,
                  maxWeeksInAdvance: v as number
                })
              );
            }}
            min={1}
            max={15}
            step={1}
            data-testid={"maximumAdvancedParkingplaceBooking"}
          />
        )}

        {/* maxAdvanced booking time for ELECTRICCHARGINGSTATIONPLACE */}
        {placeTypes.filter(t => t.id === 4) && (
          <LabeledSlider
            marginRight="5px"
            value={eChargePlSetting.maxWeeksInAdvance || 6}
            label={t("_companyMaximumAdvancedBookingLabel", {
              type: t("Electric Charging")
            })}
            onChange={(e, v) => {
              /* istanbul ignore next */
              setEChargePlSetting(prev => ({ ...prev, maxWeeksInAdvance: v as number }));
              /* istanbul ignore next */
              setNewValues?.(prevState =>
                updateCheckInSettings(prevState, BookingType.ELECTRICCHARGINGSTATIONPLACE, {
                  ...eChargePlSetting,
                  maxWeeksInAdvance: v as number
                })
              );
            }}
            min={1}
            max={15}
            step={1}
            data-testid={"maximumAdvancedElectricchargingplaceBooking"}
          />
        )}

        {/* maxAdvanced booking time for CONFERENCEZONE */}
        {zoneTypes.filter(t => t.id === 3) && (
          <LabeledSlider
            marginRight="5px"
            value={confZnSetting.maxWeeksInAdvance || 6}
            label={t("_companyMaximumAdvancedBookingLabel", {
              type: t("_bookTypeMeetingRoom")
            })}
            onChange={(e, v) => {
              /* istanbul ignore next */
              setConfZnSetting(prev => ({ ...prev, maxWeeksInAdvance: v as number }));
              /* istanbul ignore next */
              setNewValues?.(prevState =>
                updateCheckInSettings(prevState, BookingType.CONFERENCEZONE, {
                  ...confZnSetting,
                  maxWeeksInAdvance: v as number
                })
              );
            }}
            min={1}
            max={15}
            step={1}
            data-testid={"maximumAdvancedConferencezoneBooking"}
          />
        )}
      </Collapse>
    </>
  );
}
