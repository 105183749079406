import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { fitAndCorrectType } from "../BookingForm/functions/form-selection.functions";
import { namePerType } from "../FacilityManager/Components/Toolbars/InfoSidebar/AssignBox/AssignBox";
import { useRemotePatchCalendarSync } from "../../hooks/Remote/CalendarSync/useRemotePatchCalendarSync";
import { useRemoteFetchStartMsgraph } from "../../hooks/Remote/CalendarSync/useRemoteFetchStartMsgraph";
import { useRemoteDeleteMsgraph } from "../../hooks/Remote/CalendarSync/useRemoteDeleteMsgraph";
import { FabComp, MsTeamsIconComp } from "./booking-calendarSync-integration-fab.component";
import { setSettings, setUserInformation } from "../../features/Login/slices/login.slice";

type P = {
  setCalendarSyncOpen: (o: boolean) => void;
};

const CalendarSyncIntegrationBooking: React.FC<P> = ({ setCalendarSyncOpen }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { userInformation, settings } = useSelector((state: RootState) => state.login);
  const { placeTypes, zoneTypes, msGraphEnabled } = userInformation;
  const dispatch = useDispatch();

  const { data: msGraphStartUrl } = useRemoteFetchStartMsgraph();
  const {
    mutate: deleteMsGraph,
    isSuccess: isDeleteMsGraphSuccess,
    isError: isDeleteMsGraphError
  } = useRemoteDeleteMsgraph();
  const { mutateAsync: patchCalendarSync } = useRemotePatchCalendarSync();

  const [newValues, setNewValues] = useState<BookingType[]>([]);

  function postSettings() {
    patchCalendarSync({ enabledSyncs: newValues })
      .then(() => {
        enqueueSnackbar(t("Successfully activated the calendar sync setting"), {
          variant: "success"
        });
        setCalendarSyncOpen(false);
        dispatch(setSettings({ settings: { ...settings, enabledSyncs: newValues } }));
      })
      .catch(e => {
        const errCompanyNotAllowed = "Company does not allow calendar sync!";
        if (e.response.message === errCompanyNotAllowed) {
          enqueueSnackbar(t(e), { variant: "error" });
        } else {
          enqueueSnackbar(t("There was a problem activating the calendar sync"), {
            variant: "error"
          });
        }
      });
  }

  const switchPerBookingType = (bookingType: BookingType) => {
    return (
      <Switch
        data-testid={`booking-calendarSync-switch-${bookingType}`}
        checked={newValues.includes(bookingType)}
        onChange={(e, c) => updEnableSync(c, newValues, setNewValues, bookingType)}
        name={t("_calendarSyncAndIntegrationsToggle", { bookingType: namePerType(bookingType, t) })}
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
    );
  };

  useEffect(() => {
    if (settings.enabledSyncs && settings.enabledSyncs?.length) {
      setNewValues(settings.enabledSyncs as BookingType[]);
    }
  }, []);

  useEffect(() => {
    if (isDeleteMsGraphSuccess) {
      enqueueSnackbar(t("Successfully deleted the calendar sync"), { variant: "success" });
      dispatch(setUserInformation({ ...userInformation, msGraphEnabled: false }));
    }
    if (isDeleteMsGraphError) enqueueSnackbar(t("There was a server error"), { variant: "error" });
  }, [isDeleteMsGraphSuccess, isDeleteMsGraphError]);

  return (
    <>
      <Grid
        container
        direction={"row"}
        alignItems={"center"}
        data-testid="calendarSync-integration-container"
      >
        <Grid item xs={9}>
          <Typography sx={{ padding: "10px 0 10px 0" }} variant={"h6"}>
            {t("_calendarSyncAndIntegrationsTitle")}
          </Typography>
        </Grid>
        <Grid item xs sx={{ textAlign: "right" }}>
          <IconButton
            sx={{ float: "right" }}
            data-testid="btn-group-close"
            onClick={() => setCalendarSyncOpen(false)}
            size="large"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container>
        <Box sx={{ paddingLeft: "5px", paddingTop: "10px", marginRight: "20px" }}>
          <FormGroup row>
            {fitAndCorrectType(BookingType.WORKPLACE, placeTypes, zoneTypes) && (
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={switchPerBookingType(BookingType.WORKPLACE)}
                  label={t("_calendarSyncAndIntegrationsToggle", { bookingType: t("Workplace") })}
                />
              </Box>
            )}
            {fitAndCorrectType(BookingType.PARKINGPLACE, placeTypes, zoneTypes) && (
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={switchPerBookingType(BookingType.PARKINGPLACE)}
                  label={t("_calendarSyncAndIntegrationsToggle", { bookingType: t("Parking") })}
                />
              </Box>
            )}
            {fitAndCorrectType(BookingType.ELECTRICCHARGINGSTATIONPLACE, placeTypes, zoneTypes) && (
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={switchPerBookingType(BookingType.ELECTRICCHARGINGSTATIONPLACE)}
                  label={t("_calendarSyncAndIntegrationsToggle", {
                    bookingType: t("Electric Charging")
                  })}
                />
              </Box>
            )}
            {fitAndCorrectType(BookingType.CONFERENCEZONE, placeTypes, zoneTypes) && (
              <Box sx={{ mb: 1 }}>
                <FormControlLabel
                  control={switchPerBookingType(BookingType.CONFERENCEZONE)}
                  label={t("_calendarSyncAndIntegrationsToggle", {
                    bookingType: t("_bookTypeMeetingRoom")
                  })}
                />
              </Box>
            )}

            {/* Calendar Sync and Integrations */}
            <Grid container columnGap={1}>
              <Grid>
                {/* Floating Action button for MS Teams sync */}
                <FabComp
                  isConnected={msGraphEnabled}
                  iconComponent={MsTeamsIconComp}
                  startLinkUrl={msGraphStartUrl}
                  deleteLink={deleteMsGraph}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
      </Grid>

      <Button
        sx={{ mt: 4 }}
        data-testid="post-calendarSync-and-integration-btn"
        onClick={postSettings}
      >
        {t("Confirm")}
      </Button>
    </>
  );
};

export default CalendarSyncIntegrationBooking;

export function updEnableSync(
  c: boolean,
  newValues: BookingType[],
  setNewValues: (v: BookingType[]) => void,
  item: BookingType
) {
  if (c) {
    setNewValues([...newValues, item]);
  } else {
    const newVal = newValues.filter(v => v !== item);
    setNewValues(newVal);
  }
}
