import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { CropSquare, Desk, Mouse } from "@mui/icons-material";
import { ToolSelection } from "../../Views/CreateFloorPlanView/CreateFloorPlanView";
import { useTranslation } from "react-i18next";

interface Props {
  selectedTool: ToolSelection;
  onSelectTool: (tool: ToolSelection) => void;
  setShowPlaceAdd: (p: boolean) => void;
  setIsMultiPlaces: (m: boolean) => void;
  setTypeOfPlaces: (t: number) => void;
  setNumberOfPlacesAdd: (a: number) => void;
  setShowZoneAdd: (z: boolean) => void;
  setTypeOfZone: (t: number) => void;
  isFloorPlanForService?: boolean;
}

export function SideToolbar({
  onSelectTool,
  selectedTool,
  setShowPlaceAdd,
  setIsMultiPlaces,
  setTypeOfPlaces,
  setNumberOfPlacesAdd,
  setShowZoneAdd,
  setTypeOfZone,
  isFloorPlanForService = false
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();

  const resetPlaceState = () => {
    setShowPlaceAdd(false);
    setIsMultiPlaces(false);
    setTypeOfPlaces(1);
    setNumberOfPlacesAdd(0);
  };

  const resetZoneState = () => {
    setShowZoneAdd(false);
    setTypeOfZone(1);
  };
  const selectZoneCreate = () => {
    setShowZoneAdd(true);
    setTypeOfZone(1);
  };

  return (
    <Box
      sx={{
        border: "1px solid #00000088",
        backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#ffffff",
        opacity: 0.95,
        position: "absolute",
        top: 65,
        m: 1,
        borderRadius: 2,
        display: isFloorPlanForService ? "none" : "initial"
      }}
    >
      <Grid container sx={{ width: 100 }} data-testid="tool-container">
        {/** normal tool to select zones and desks */}
        <Grid
          item
          xs={6}
          sx={{ backgroundColor: selectedTool === undefined ? "blue" : undefined, borderRadius: 2 }}
        >
          <Tooltip
            title={<Typography>{t("SelectSideToolbarLabel")}</Typography>}
            aria-label={"SelectSideToolbarLabel"}
          >
            <IconButton
              data-testid="tool-btn-selectDesk"
              onClick={() => {
                onSelectTool(undefined);
                resetPlaceState();
                resetZoneState();
              }}
              size={"large"}
            >
              <Mouse sx={{ color: selectedTool === undefined ? "white" : undefined }} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/** tool to set new zone */}
        <Grid
          item
          xs={6}
          sx={{
            backgroundColor: selectedTool === "ZONE.NEW" ? "blue" : undefined,
            borderRadius: 2
          }}
        >
          <Tooltip
            title={<Typography>{t("CreateZonesSideToolbarLabel")}</Typography>}
            aria-label={"CreateZonesSideToolbarLabel"}
          >
            <IconButton
              data-testid="tool-btn-createZone"
              onClick={() => {
                onSelectTool("ZONE.NEW");
                selectZoneCreate();
                resetPlaceState();
              }}
              size={"large"}
            >
              <CropSquare sx={{ color: selectedTool === "ZONE.NEW" ? "white" : undefined }} />
            </IconButton>
          </Tooltip>
        </Grid>

        {/** tool to set new desks */}
        <Grid
          item
          xs={6}
          sx={{
            backgroundColor: selectedTool === "DESK.NEW" ? "blue" : undefined,
            borderRadius: 2
          }}
        >
          <Tooltip
            title={<Typography>{t("CreatePlacesSideToolbarLabel")}</Typography>}
            aria-label={"CreatePlacesSideToolbarLabel"}
          >
            <IconButton
              data-testid="tool-btn-createDesk"
              onClick={() => {
                onSelectTool("DESK.NEW");
                setShowPlaceAdd(true);
                resetZoneState();
              }}
              size={"large"}
            >
              <Desk sx={{ color: selectedTool === "DESK.NEW" ? "white" : undefined }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Box>
  );
}
