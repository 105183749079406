import { Chip, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircleOutline } from "@mui/icons-material";

interface Props {
  notCheckedInPercentage: string;
}

export function NoCheckedInTimeAverage({ notCheckedInPercentage }: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={<Typography>{t("NoCheckedInTimeAverageLabel")}</Typography>}
      aria-label={"NoCheckedInTimeAverageLabel"}
    >
      <Chip
        sx={{ mr: 1, mb: 1 }}
        data-testid={"no-checked-in-time-average-label"}
        icon={<CheckCircleOutline />}
        label={notCheckedInPercentage}
        color={"warning"}
      />
    </Tooltip>
  );
}
