import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BookingScheduleInterface } from "../../features/Booking-Form/typings/booking.types";
import { ILocationInventory } from "../FacilityManager/Domain/Types/FloorPlan/LocationInventory.type";
import { IFloorInventory } from "../FacilityManager/Domain/Types/FloorPlan/FloorInventory.type";

type P = {
  schedule: BookingScheduleInterface;
};

export const ScheduleEventLocation: React.FC<P> = ({ schedule }) => {
  const { t } = useTranslation();
  const {
    locationName,
    location,
    floorName,
    bookingInventory: { locationInventory, floorInventory }
  } = schedule;
  const { address, postalCode, city } = generateAddressDetail(locationInventory, floorInventory);

  return (
    <Box>
      <Typography data-testid="schedule-item-location">
        {locationName !== "Mobile Working" ? locationName ?? location : t("Mobile Working")}
        {" - "}
        {locationName !== "Mobile Working" ? floorName : ""}
      </Typography>
      {locationName !== "Mobile Working" && (locationInventory || floorInventory) && (
        <>
          <Typography sx={{ mt: 0.2 }}>{address}</Typography>
          <Typography sx={{ mt: 0.2 }}>
            {postalCode}
            {", "}
            {city}
          </Typography>
        </>
      )}
    </Box>
  );
};

export function generateAddressDetail(
  locInventory: ILocationInventory | undefined,
  floInventory: IFloorInventory | undefined
) {
  let address: string = "";
  let postalCode: string = "";
  let city: string = "";

  if (locInventory) {
    address = `${locInventory.address1} ${locInventory.address2}`;
    postalCode = locInventory.postalCode;
    city = locInventory.city;
    return { address, postalCode, city };
  }

  if (floInventory) {
    address = `${floInventory.locationInventory.address1} ${floInventory.locationInventory.address2}`;
    postalCode = floInventory.locationInventory.postalCode;
    city = floInventory.locationInventory.city;
    return { address, postalCode, city };
  }
  return { address, postalCode, city };
}
