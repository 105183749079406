import { ElementType } from "react";
import { Chip, Tooltip, Typography } from "@mui/material";
import { SvgIconProps } from "@mui/material/SvgIcon";

type Props = {
  tooltipValue: string;
  value: string | number;
  Icon: ElementType<SvgIconProps>;
  color: "primary" | "warning";
};

export function CustomChip({ tooltipValue, value, Icon, color }: Props) {
  return (
    <Tooltip title={<Typography>{tooltipValue}</Typography>} aria-label={tooltipValue}>
      <Chip
        sx={{ mr: 1, mb: 1 }}
        data-testid={"custom-chip-label"}
        icon={<Icon />}
        label={value}
        color={color}
      />
    </Tooltip>
  );
}
