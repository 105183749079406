import React from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "../../app/helpers";
import { RootState } from "../../app/rootReducer";
import {
  setSelectedBookingType,
  setSelectedLocation,
  setSelectedWeekdays
} from "../../features/Reports/slices/report.slice";
import { fetchEntities, fetchReport } from "../../features/Reports/thunks/report.thunk";
import { AreaChartsReport } from "./area-chart.component";
import ContextPopperReport from "./context-popper-report.component";
import CapacityPerHourReport from "./capacity-per-hour-report.component";
import { BookingType } from "../../features/Booking-Form/typings/booking-inputs";
import { initialWeekdays } from "../../features/Reports/initialWeekdays.constant";

/**
 * @description Component that renders the user menu dropdown in the right top corner.
 * @version 0.1.0
 */

type P = {
  name: string;
  id: number;
  averageCapacity: number;
  mode: boolean;
};

const LocationCapacityMarker: React.FC<P> = ({ name, id, mode }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    userInformation: { sub, placeTypes, zoneTypes }
  } = useSelector((state: RootState) => state.login);
  const {
    selectedLocation,
    selectedTimeframe,
    capacitySelectedLocations,
    selectableEntities,
    calculateWithoutCosts,
    zoneRestrictions
  } = useSelector((state: RootState) => state.report);

  const selectedLocationData = capacitySelectedLocations?.find(
    location => location.id === selectedLocation.id
  )?.capacity;

  const dispatch = useDispatch();

  return (
    <Grid
      sx={{ cursor: "hover" }}
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {selectedTimeframe && (
        <ContextPopperReport
          content={
            <div>
              {/* <Grid container direction="column">
                <Grid xs={12}>
                  <DevicesFilter />
                </Grid>
                <Grid xs={12}>
                  <DeviceCategoryFilter />
                </Grid>
                <Grid xs={12}>
                  <PlaceCategoryFilter />
                </Grid>
              </Grid> */}
              {!selectedLocationData && (
                <>
                  <Skeleton
                    variant="rectangular"
                    sx={{ margin: "10px 15px 15px 0px" }}
                    width={400}
                    height={288}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ margin: "10px 15px 15px 0px" }}
                    width={400}
                    height={288}
                  />
                </>
              )}
              <Fade mountOnEnter unmountOnExit in={!!selectedTimeframe}>
                <Grid
                  container
                  sx={{ maxWidth: 400 }}
                  justifyContent={"center"}
                  data-testid="context-popper-report-parent-grid"
                >
                  {selectedLocationData && (
                    <>
                      <Grid item xs />
                      <Grid item xs={9} sx={{ alignSelf: "center" }}>
                        <Typography align={"center"} lineHeight={2.5}>
                          {t("Users visited")}:{" "}
                          {selectedLocationData?.distinctTotalUsers?.length || 0}
                        </Typography>
                      </Grid>
                      <Grid item xs sx={{ textAlign: "right" }}>
                        <IconButton
                          sx={{ float: "right" }}
                          data-testid="btn-group-close"
                          onClick={() => {
                            dispatch(setSelectedLocation({ id: id, open: false, sub: sub }));
                          }}
                          size="large"
                        >
                          <Close />
                        </IconButton>
                      </Grid>

                      <AreaChartsReport
                        width={isMobile ? "90%" : "45vw"}
                        height="265px"
                        capacityObjects={[
                          { id: selectedLocation.id, data: selectedLocationData, label: name }
                        ]}
                      />
                      <CapacityPerHourReport
                        width={isMobile ? "90%" : "45vw"}
                        height="265px"
                        capacityObjects={[
                          { id: selectedLocation.id, data: selectedLocationData, label: name }
                        ]}
                      />

                      <Button
                        data-testid="loc-capa-marker-get-more-details-btn"
                        onClick={() => {
                          const availableTypes = [
                            ...placeTypes.filter(typ => typ.id === 1 || 2 || 4),
                            ...zoneTypes.filter(typ => typ.id === 3)
                          ];

                          dispatch(setSelectedBookingType(availableTypes[0].name as BookingType));
                          dispatch(setSelectedWeekdays(initialWeekdays));
                          dispatch(setSelectedLocation({ id: id, open: false, sub: sub }));
                          dispatch(
                            fetchEntities({
                              id: id,
                              sub: sub,
                              cost: calculateWithoutCosts,
                              start: selectedTimeframe.timeframeStart,
                              end: selectedTimeframe.timeframeEnd,
                              bookingType: availableTypes[0].name as BookingType,
                              firstFloor: selectableEntities?.find(
                                location => location.id === selectedLocation.id
                              )?.floors[0]?.inventoryId,
                              weekdays: initialWeekdays
                            })
                          );
                        }}
                      >
                        {t("Get more details")}
                      </Button>
                    </>
                  )}
                </Grid>
              </Fade>
            </div>
          }
          open={selectedLocation.open && id === selectedLocation.id}
          placement={isMobile ? "auto" : "left"}
          onClose={() => dispatch(setSelectedLocation({ id: id, open: false, sub: sub }))}
        >
          <div>
            <Tooltip title={<Typography>{name}</Typography>} aria-label={name}>
              <Box
                data-testid="location-capa-marker-typo-box"
                onClick={() => {
                  dispatch(setSelectedLocation({ id: id, open: true, sub: sub }));
                  dispatch(
                    fetchReport({
                      id: id,
                      sub: sub,
                      cost: calculateWithoutCosts,
                      start: selectedTimeframe.timeframeStart,
                      end: selectedTimeframe.timeframeEnd,
                      filterType: "location",
                      status: true,
                      update: true,
                      zoneBooking: mode,
                      companyId: mode ? zoneRestrictions?.company?.id : undefined,
                      zoneBookingObject: mode
                        ? zoneRestrictions?.object?.zoneBookingObject
                        : undefined
                    })
                  );
                }}
                sx={{
                  /**
                   * previously used getColor(averageCapacity), but currently don't make a data request on the google map
                   * so, for now, use company color on the marker
                   */
                  background: theme.palette.primary.main,
                  textDecoration: "none",
                  borderRadius: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px solid white",
                  width: 30,
                  height: 30,
                  cursor: "pointer",
                  color: "white !important",
                  boxShadow: "10px 10px 20px -10px rgba(0,0,0,0.75)",
                  "&:hover": {
                    background: "#131421 !important"
                  }
                }}
              >
                {
                  <Typography data-testid="location-capa-marker-typo">
                    {name.slice(0, 1)}
                  </Typography>
                }
              </Box>
            </Tooltip>
          </div>
        </ContextPopperReport>
      )}
    </Grid>
  );
};

export default LocationCapacityMarker;
