import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { Add, PlaylistAdd, Desk, LocalParking, EvStation } from "@mui/icons-material";
import { PlaceSmallIcon, PlaceMediumIcon } from "../../Canvas/Partials/place-icons.component";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IPlace, IPlaceType } from "../../../Domain/Types/FloorPlan/Place.type";

interface Props {
  isMultiPlaces: boolean;
  setIsMultiPlaces: (m: boolean) => void;
  setNumberOfPlacesAdd: (n: number) => void;
  typeOfPlaces: number;
  setTypeOfPlaces: (t: number) => void;
  availableTypes: IPlace[];
}

export function PlaceSideToolbar({
  isMultiPlaces,
  setIsMultiPlaces,
  setNumberOfPlacesAdd,
  typeOfPlaces,
  setTypeOfPlaces,
  availableTypes
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const iconFilledWithTheme = theme.palette.mode === "dark" ? "#ffffff" : "#000000";
  const iconStrokeWithTheme = theme.palette.mode === "dark" ? "#2b2b2b" : "#DBDBDB";
  const placeIconBoxClass = {
    width: 160,
    margin: "5px 0",
    border: theme.palette.mode === "dark" ? "1px solid #ffffff" : "1px solid #000000",
    textAlign: "center",
    cursor: "pointer"
  };
  const placeItemClass = { paddingTop: "5px" };
  const boxBgColor = theme.palette.mode === "dark" ? "#000000" : "#ffffff";
  const placeBoxStyle = {
    border: "1px solid #00000088",
    backgroundColor: boxBgColor,
    opacity: 0.95,
    borderRadius: 2
  };

  const avaialbleTypeNames = availableTypes.map(t => t.name);
  const [placeNumberBoxOpen, setPlaceNumberBoxOpen] = useState(false);

  return (
    <>
      <Grid
        container
        data-testid="place-side-toolbar-container"
        sx={{
          position: "absolute",
          top: "176px",
          left: "8px",
          flexDirection: "column",
          alignContent: "flex-start",
          width: "auto"
        }}
      >
        {/* select place types */}
        <Grid item data-testid="place-side-toolbar-type-item" sx={{ width: "fit-content" }}>
          <Box sx={{ ...placeBoxStyle }}>
            <Grid container sx={{ width: 100 }}>
              {/** create place type 1 desk */}
              {avaialbleTypeNames.includes(IPlaceType.WORKPLACE) && (
                <Grid
                  item
                  xs={6}
                  sx={{ backgroundColor: typeOfPlaces === 1 ? "blue" : undefined, borderRadius: 2 }}
                >
                  <Tooltip
                    title={<Typography>{t("Workplace")}</Typography>}
                    aria-label={"Workplace"}
                  >
                    <IconButton
                      data-testid="btn-select-plType-1"
                      onClick={() => {
                        setTypeOfPlaces(1);
                        setPlaceNumberBoxOpen(true);
                      }}
                      size={"large"}
                    >
                      <Desk sx={{ color: typeOfPlaces === 1 ? "white" : undefined }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}

              {/** create place type 2 park place */}
              {avaialbleTypeNames.includes(IPlaceType.PARKINGPLACE) && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: typeOfPlaces === 2 ? "blue" : undefined,
                    borderRadius: 2
                  }}
                >
                  <Tooltip title={<Typography>{t("Parking")}</Typography>} aria-label={"Parking"}>
                    <IconButton
                      data-testid="btn-select-plType-2"
                      onClick={() => {
                        setTypeOfPlaces(2);
                        setPlaceNumberBoxOpen(true);
                      }}
                      size={"large"}
                    >
                      <LocalParking sx={{ color: typeOfPlaces === 2 ? "white" : undefined }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}

              {/** create place type 4 electric charging station */}
              {avaialbleTypeNames.includes(IPlaceType.ELECTRICCHARGINGSTATIONPLACE) && (
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: typeOfPlaces === 4 ? "blue" : undefined,
                    borderRadius: 2
                  }}
                >
                  <Tooltip
                    title={<Typography>{t("Electric Charging")}</Typography>}
                    aria-label={"Electric Charging"}
                  >
                    <IconButton
                      data-testid="btn-select-plType-4"
                      onClick={() => {
                        setTypeOfPlaces(4);
                        setPlaceNumberBoxOpen(true);
                      }}
                      size={"large"}
                    >
                      <EvStation sx={{ color: typeOfPlaces === 4 ? "white" : undefined }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>

        {/* select single place or multi places */}
        <Grid item data-testid="place-side-toolbar-number-item" sx={{ width: "fit-content" }}>
          {placeNumberBoxOpen && (
            <Box sx={{ ...placeBoxStyle, width: "fit-content" }}>
              <Grid container sx={{ width: 100 }}>
                {/** create new single place */}
                <Grid
                  item
                  xs={6}
                  sx={{ backgroundColor: isMultiPlaces ? undefined : "blue", borderRadius: 2 }}
                >
                  <Tooltip
                    title={<Typography>{t("SinglePlaceSideToolbarLabel")}</Typography>}
                    aria-label={"SinglePlaceSideToolbarLabel"}
                  >
                    <IconButton
                      data-testid="btn-create-single-pl"
                      onClick={() => {
                        setIsMultiPlaces(false);
                        setNumberOfPlacesAdd(1);
                      }}
                      size={"large"}
                    >
                      <Add sx={{ color: isMultiPlaces ? undefined : "white" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>

                {/** create new multi places */}
                <Grid
                  item
                  xs={6}
                  sx={{
                    backgroundColor: isMultiPlaces ? "blue" : undefined,
                    borderRadius: 2
                  }}
                >
                  <Tooltip
                    title={<Typography>{t("MultiPlacesSideToolbarLabel")}</Typography>}
                    aria-label={"MultiPlacesSideToolbarLabel"}
                  >
                    <IconButton
                      data-testid="btn-create-multi-pl"
                      onClick={() => {
                        setIsMultiPlaces(true);
                        setNumberOfPlacesAdd(2);
                      }}
                      size={"large"}
                    >
                      <PlaylistAdd sx={{ color: isMultiPlaces ? "white" : undefined }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          )}

          {isMultiPlaces && (
            <Box sx={{ ...placeBoxStyle }}>
              <Grid
                item
                xs={12}
                id="place-icon-container"
                container
                sx={{ width: 190, padding: "5px", placeContent: "center" }}
              >
                <Grid
                  id="place-icon-box-2"
                  item
                  sx={placeIconBoxClass}
                  data-testid="place-icon-box-2"
                  onClick={() => setNumberOfPlacesAdd(2)}
                >
                  <Grid id="place-icon-item-2" item sx={placeItemClass}>
                    <PlaceMediumIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceMediumIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                  </Grid>
                </Grid>

                <Grid
                  id="place-icon-box-3"
                  item
                  sx={placeIconBoxClass}
                  data-testid="place-icon-box-3"
                  onClick={() => setNumberOfPlacesAdd(3)}
                >
                  <Grid id="place-icon-item-3" item sx={placeItemClass}>
                    <PlaceMediumIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceMediumIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceMediumIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                  </Grid>
                </Grid>

                <Grid
                  id="place-icon-box-4"
                  item
                  sx={placeIconBoxClass}
                  data-testid="place-icon-box-4"
                  onClick={() => setNumberOfPlacesAdd(4)}
                >
                  <Grid
                    id="place-icon-item-4"
                    item
                    xs={6}
                    sx={{ ...placeItemClass, margin: "auto" }}
                  >
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                  </Grid>
                </Grid>

                <Grid
                  id="place-icon-box-6"
                  item
                  sx={placeIconBoxClass}
                  data-testid="place-icon-box-6"
                  onClick={() => setNumberOfPlacesAdd(6)}
                >
                  <Grid
                    id="place-icon-item-6"
                    item
                    xs={8}
                    sx={{ ...placeItemClass, margin: "auto" }}
                  >
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                    <PlaceSmallIcon fill={iconFilledWithTheme} stroke={iconStrokeWithTheme} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
