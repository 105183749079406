import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { useTranslation } from "react-i18next";

export function useShowMultiPlacesItem(showMultiPlacesEdit: boolean) {
  const { t } = useTranslation();

  const infoSideBarTheme = { color: "#6f6f6f", overflow: "scroll" }; // #008000

  const useToggleButton = false; // set toggle button to select editing each places or multiple places
  const showPlaceEditTooltip = showMultiPlacesEdit ? t("Edit Each Places") : t("Edit Multi Places");
  const showPlaceEditIcon = showMultiPlacesEdit ? (
    <KeyboardDoubleArrowDownIcon />
  ) : (
    <KeyboardDoubleArrowUpIcon />
  );

  return { showPlaceEditTooltip, showPlaceEditIcon, infoSideBarTheme, useToggleButton };
}
